<template>
    <v-container fluid>
        <v-card class="text-sm overflow-hidden px-md-16 px-sm-4 px-4 d-flex justify-center mx-auto">
            <v-form style="width: 100%" class="pt-10" lazy-validation v-model="validForm" ref="form">
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            v-model="name"
                            :prepend-inner-icon="userIcon"
                            :rules="nameRules"
                            label="Nome"
                            placeholder="Nome"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"></v-text-field>

                        <v-text-field
                            v-model="username"
                            :prepend-inner-icon="usernameIcon"
                            :rules="nameRules"
                            label="Nome de utilizador"
                            placeholder="Nome de utilizador"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"></v-text-field>

                        <v-text-field
                            v-model="email"
                            :prepend-inner-icon="emailIcon"
                            :rules="emailRules"
                            label="Email"
                            placeholder="Email"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            type="email"
                            class="mb-4"></v-text-field>

                        <v-switch inset dense label="Validado" v-model="emailValidated" class="mt-n2"></v-switch>

                        <v-text-field
                            v-model="statusMessage"
                            :prepend-inner-icon="pencilIcon"
                            label="Status Message"
                            placeholder="Status Message"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"></v-text-field>

                        <v-textarea
                            v-model="bio"
                            :prepend-inner-icon="pencilIcon"
                            label="Bio"
                            outlined
                            dense
                            no-resize
                            counter
                            maxlength="100"></v-textarea>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            v-model="nickname"
                            :prepend-inner-icon="pencilIcon"
                            label="Nickname"
                            placeholder="Nickname"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"></v-text-field>

                        <v-text-field
                            :append-icon="showPassword ? eye : eyeOff"
                            :type="showPassword ? 'text' : 'password'"
                            label="Senha"
                            placeholder="Senha"
                            required
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"
                            @click:append="showPassword = !showPassword"
                            v-model="password"></v-text-field>

                        <v-switch
                            v-model="changePasswordRequiredSwitch"
                            inset
                            dense
                            label="Exigir alteração de senha"
                            class="mt-n0"
                            :disabled="randomPasswordSwitch ? true : false"></v-switch>

                        <v-switch
                            v-model="randomPasswordSwitch"
                            inset
                            dense
                            label="Gerar senha aleatória e enviar por email"
                            class="mt-n2"
                            @change="
                                randomPasswordSwitch
                                    ? (changePasswordRequiredSwitch = true)
                                    : (changePasswordRequiredSwitch = false)
                            "></v-switch>

                        <v-select
                            v-model="roles"
                            outlined
                            dense
                            :items="items"
                            label="Roles"
                            class="mt-md-3"></v-select>

                        <v-switch
                            v-model="enterPredefinedRoom"
                            inset
                            dense
                            label="Entrar em canais predefinidos"
                            class="mt-n3"></v-switch>

                        <v-switch
                            v-model="welcomeEmail"
                            inset
                            dense
                            label="Enviar e-mail de boas vindas"
                            class="mt-n3 mb-4"></v-switch>
                    </v-col>
                </v-row>
                <div class="d-flex justify-center mt-4 pb-10">
                    <v-btn type="reset" outlined class="mr-2"> Cancelar </v-btn>
                    <v-btn color="primary" class="mr-2" @click="validate"> Guardar </v-btn>
                </div>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
    import { mdiAccountOutline, mdiEmailOutline, mdiAt, mdiPencil, mdiKeyVariant, mdiEyeOff, mdiEye } from '@mdi/js';
    export default {
        data() {
            return {
                nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
                ],
                emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+..+/.test(v) || 'E-mail must be valid'],

                name: null,
                username: null,
                email: null,
                statusMessage: null,
                bio: null,
                nickname: null,
                password: null,
                roles: null,
                enterPredefinedRoom: null,
                welcomeEmail: null,
                emailValidated: null,
                validForm: true,

                userIcon: mdiAccountOutline,
                usernameIcon: mdiAt,
                emailIcon: mdiEmailOutline,
                pencilIcon: mdiPencil,
                keyIcon: mdiKeyVariant,
                showPassword: false,
                eye: mdiEye,
                eyeOff: mdiEyeOff,

                items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
                perfectScrollbarOptions: {
                    maxScrollbarLength: 60,
                    wheelPropagation: false,
                },

                randomPasswordSwitch: false,
                changePasswordRequiredSwitch: false,
            };
        },
        methods: {
            validate() {
                this.$refs.form.validate();
            },
        },
    };
</script>
